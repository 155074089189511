import React, { useEffect, useState } from 'react';
import CrudTable from '../table/crudtable'
import { exportDocumentConfigurationService, documentationService, customerService } from '../../_services'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import { SERVER_URL } from '../../config';
import { Modal, Button, Form } from 'react-bootstrap'
import { history } from '../../_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faFlask } from '@fortawesome/free-solid-svg-icons'

function ExportDocumentConfigurationList() {

	const [customers, setCustomers] = useState([]);
	const [selectedExportDocumentConfiguration, setSelectedExportDocumentConfiguration] = useState(null);
	const [selectedDocumentation, setSelectedDocumentation] = useState('');
	const [showTestModal, setShowTestModal] = useState(false);
	const [backdrop, setBackdrop] = useState(false);
	const {t} = useTranslation('common');

	useEffect(() => {
        if (localStorage.getItem("exportDocumentConfiguration-documentId")) {
            setSelectedDocumentation(localStorage.getItem("exportDocumentConfiguration-documentId"))
        }

        customerService.list().then(data => {
            setCustomers(data)
        })
    }, []);

	const initialState = {
		name: '',
		description: '',
		type: 'POWERPOINT',
		nodes: []
	}

	const test = (id) => {
		setSelectedExportDocumentConfiguration(id)
		setShowTestModal(true)
	}

	const onHide = () => {
		setShowTestModal(false)
		setBackdrop(false)
    }

    const onAddClick = (row) => {
	    console.log('onAddClick')
		history.push("/exportDocumentConfiguration/create")
    }

    const onEditClick = (row) => {
	    console.log('onEditClick')
		history.push("/exportDocumentConfiguration/" + row.id)
    }

    const copy = (configurationId) => {
        console.log(configurationId)

		exportDocumentConfigurationService.copy(configurationId).then(data => {
            window.location.reload()
        })
    }

	const columns = [
		{
			dataField: 'name',
			text: t('exportdocumentconfiguration.columns.name'),
			sort: true
		},
		{
			dataField: 'description',
			text: t('exportdocumentconfiguration.columns.description'),
			formatter: cell => cell || '-',
			sort: true
		},
		{
			dataField: 'type',
			text: t('exportdocumentconfiguration.columns.type'),
			sort: true
		},
		{
			dataField: 'customers',
			text: 'Kunder',
			formatter: (cell, row) => {
				return customers.filter(customer => customer.exportDocumentConfigurations.some(conf => conf.id == row.id)).length
			}
		},
		{
			dataField: 'id',
			text: '',
			style: {
		        width: '85px'
		    },
			formatter: (cell) => {
				return <span>
					<Button variant="outline-dark" size="sm" onClick={e => test(cell)} title="Testa">
						<FontAwesomeIcon icon={faFlask}/>
					</Button>
					<span>&nbsp;&nbsp;</span>
					<Button variant="outline-dark" size="sm" onClick={e => copy(cell)} title="Kopiera">
						<FontAwesomeIcon icon={faCopy}/>
					</Button>
				</span>
			}
		}
	]

	const onClick = () => {
		localStorage.setItem("exportDocumentConfiguration-documentId", selectedDocumentation);
		window.location.href = SERVER_URL + '/exportDocumentConfiguration/test/?documentation=' + selectedDocumentation + '&exportDocumentConfiguration=' + selectedExportDocumentConfiguration
	}

	return (
		<div>
			<h2>
				{t('exportdocumentconfiguration.label')}
			</h2>
			{ customers && customers.length > 0 &&
				<CrudTable
					service={exportDocumentConfigurationService}
					columns={columns}
					initialState={initialState}
					//form={<ExportDocumentConfigurationForm />}
					sort={{dataField: 'name', order: 'asc'}}
					modalSize="lg"
					onAddClick={onAddClick}
					onEditClick={onEditClick}
				/>
			}
			<Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={showTestModal}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>Test</Modal.Title>
				</Modal.Header>
				<Modal.Body>
	                <Form.Group controlId="documentation">
	                    <Form.Label>
		                    Id
		                </Form.Label>
						<Form.Control required type="text" placeholder="Id" name="name" value={selectedDocumentation} onChange={e => setSelectedDocumentation(e.target.value)}/>
	                </Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={e => onClick(e)}
						disabled={!selectedDocumentation}
					>
						TEST
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	)
}

export default ExportDocumentConfigurationList;