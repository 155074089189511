import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import { SERVER_URL } from '../../config';
import { Button, Modal, Row, Col, Form, Alert } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { authService, documentationComparisonService, templateService, photoService, documentationTaskService, exportConfigurationService, exportEventService } from '../../_services'
import moment from 'moment'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faFilePowerpoint, faExclamationTriangle, faCheck, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

function DocumentationComparisonDetails() {

	const {documentationName} = useParams();
	const [documentations, setDocumentations] = useState([]);
	const [exportEvents, setExportEvents] = useState([]);
	const [documentationTasks, setDocumentationTasks] = useState([]);
	const [templates, setTemplates] = useState([])
	const [selectedTemplates, setSelectedTemplates] = useState([])
	const [templateTaskNames, setTemplateTaskNames] = useState([])
	const [photos, setPhotos] = useState([]);
	const [showPhotoModal, setShowPhotoModal] = useState(false);
	const [exportConfigurations, setExportConfigurations] = useState([]);
	const [showExportModal, setShowExportModal] = useState(false);
	const [selectedExportConfigurations, setSelectedExportConfigurations] = useState([]);
	const [exportParams, setExportParams] = useState({projectId: ''});
	const [showNoExportConfigurationsSelectedError, setShowNoExportConfigurationsSelectedError] = useState(false);
	const [showNoPhotosSelectedError, setShowNoPhotosSelectedError] = useState(false);
	const [isExporting, setIsExporting] = useState(false);
	const [exportStartTime, setExportStartTime] = useState('');
	const [timer, setTimer] = useState(null);
	const [backdrop, setBackdrop] = useState(false);
	const [alias, setAlias] = useState('');
	const [selectedPhotos, setSelectedPhotos] = useState([]);
	const [selectedPhoto, setSelectedPhoto] = useState(null);
    const {t} = useTranslation('common');
    const dateFormat = 'yyyy-MM-DD HH:mm:ss'
    const dispatch = useDispatch();

    useEffect(() => {
        console.log('useEffect')

		documentationComparisonService.findAllByDocumentationName(documentationName).then(documentations => {
            setDocumentations(documentations)
            if (documentations.length > 0) {
                setAlias(documentations[0].alias)
            }

            var exportEvents = []
            documentations.forEach(documentation => {
                if (documentation.exportEvents && documentation.exportEvents.length > 0) {
					exportEvents = exportEvents.concat(documentation.exportEvents)
                }
            })
            exportEvents.sort((a, b) => a.dateCreated.localeCompare(b.dateCreated))
            setExportEvents(exportEvents)

            templateService.list(50).then(templates => {
		        templates = templates.filter(template => {
					return documentations.some(documentation => documentation.templateId == template.id)
				})
	            templates.sort((a, b) => {
	                if (a.position != b.position) {
	                    return a.position - b.position
	                }
	                return a.name.localeCompare(b.name)
	            })
	            setTemplates(templates)
	            setSelectedTemplates(templates)

				var templateTaskNames = []
				templates.forEach(template => {
					template.tasks.forEach(task => {
						let taskName = task.name.replace("_pre", "_").replace("_post", "_")
						if (templateTaskNames.indexOf(taskName) < 0) {
							templateTaskNames.push(taskName)
						}
					})
				})
				console.log(templateTaskNames)
				setTemplateTaskNames(templateTaskNames)
	        })
        })

        documentationTaskService.findAllByDocumentationName(documentationName, 100).then(data => {
            setDocumentationTasks(data)
        })

        photoService.findAllByDocumentationName(documentationName, 100).then(data => {
            var filteredData = data.filter(photo => photo.hasData)
            setPhotos(filteredData)
            let photoIds = filteredData.map(photo => photo.id)
            setSelectedPhotos(photoIds)
        })

		if (authService.hasRoles(['ROLE_EXPORT_DOCUMENTATION'])) {
			exportConfigurationService.list().then(data => {
	            data.sort((a, b) => a.name.localeCompare(b.name))
	            setExportConfigurations(data)
	            setSelectedExportConfigurations(data.map(exportConfiguration => exportConfiguration.id))
	        })
	        documentationComparisonService.getProjectId(documentationName).then(projectId => {
	            if (projectId) {
	                setExportParams({projectId: projectId})
	            }
	        })
		}
    }, [])

    const downloadZipFile = () => {
		console.log('downloadZipFile')

		if (selectedPhotos.length == 0) {
			return
		}

		let photoIds = selectedPhotos.join('&photo=')
		let userId = authService.getCurrentUser().id

		window.location.href = SERVER_URL + '/documentationComparison/downloadZipFile?photo=' + photoIds + '&userId=' + userId
    }

    const downloadPowerPoint = () => {
		console.log('downloadPowerPoint')

		if (selectedPhotos.length == 0) {
			return
		}

		let photoIds = selectedPhotos.join('&photo=')
		let userId = authService.getCurrentUser().id

		window.location.href = SERVER_URL + '/documentationComparison/downloadPowerPoint?photo=' + photoIds + '&userId=' + userId
    }

    const getPhotosForTemplateTask = (template, templateTaskName) => {
        let documentationIds = documentations.filter(documentation => documentation.templateId == template.id).map(documentation => {
            return documentation.id
        })
        documentationIds = new Set(documentationIds)
        return photos.filter(photo => {
            if (photo.alias) {
                return photo.alias.startsWith(templateTaskName) && documentationIds.has(photo.documentationId)
            }
            return photo.name.startsWith(templateTaskName) && documentationIds.has(photo.documentationId)
        })
    }

    const getTemplateForExportEvent = (exportEvent) => {
        var documentation = documentations.find(documentation => documentation.id == exportEvent.documentation)
        if (documentation) {
            var template = templates.find(template => template.id == documentation.templateId)
            if (template) {
                return template.name
            }
        }
        return '-'
    }

    const toggleTemplate = (template) => {
        var newSelectedTemplates = [...selectedTemplates]
        let indexOfTemplate = selectedTemplates.indexOf(template);
        var documentationIds = documentations.filter(documentation => documentation.templateId == template.id).map(documentation => {
            return documentation.id
        })
        documentationIds = new Set(documentationIds)
		if (indexOfTemplate != -1) {
			newSelectedTemplates.splice(indexOfTemplate, 1)

			var newSelectedPhotos = selectedPhotos.filter(photoId => {
				var keep = true
				var photo = photos.find(photo => photo.id === photoId)
				if (photo) {
					if (documentationIds.has(photo.documentationId)) {
						keep = false
					}
				}
				return keep
			})
			setSelectedPhotos(newSelectedPhotos)
		} else {
			newSelectedTemplates.push(template)

	        var newSelectedPhotos = [...selectedPhotos]
	        photos.forEach(photo => {
				if (documentationIds.has(photo.documentationId)) {
					newSelectedPhotos.push(photo.id)
				}
			})
			setSelectedPhotos(newSelectedPhotos)
		}
		newSelectedTemplates.sort((a, b) => {
            if (a.position != b.position) {
                return a.position - b.position
            }
            return a.name.localeCompare(b.name)
        })
		setSelectedTemplates(newSelectedTemplates)

		var max = 0
		newSelectedTemplates.forEach(template => {
			max = Math.max(template.tasks.length, max)
		})
		var templateTaskNames = []
		for (var i = 0; i < max; i++) {
			newSelectedTemplates.forEach(template => {
				if (template.tasks.length > i) {
					let task = template.tasks[i]
					let taskName = task.name.replace("_pre", "_").replace("_post", "_")
					if (templateTaskNames.indexOf(taskName) < 0) {
						templateTaskNames.push(taskName)
					}
				}
			})
		}
		console.log(templateTaskNames)
		setTemplateTaskNames(templateTaskNames)
    }

    const togglePhoto = (photo) => {
        console.log('togglePhoto')
        var newSelectedPhotos = [...selectedPhotos]
        let indexOfPhoto = selectedPhotos.indexOf(photo.id);
		if (indexOfPhoto != -1) {
			newSelectedPhotos.splice(indexOfPhoto, 1)
		} else {
			newSelectedPhotos.push(photo.id)
		}
		setSelectedPhotos(newSelectedPhotos)
    }

    const findTemplateTaskByName = (template, templateTaskName) => {
		return template.tasks.find(task => task.name.startsWith(templateTaskName))
    }

    const onHidePhotoModal = () => {
		setShowPhotoModal(false)
		setBackdrop(false)
    }

    const onHideExportModal = () => {
		setShowExportModal(false)
		setBackdrop(false)
		if (timer) {
			clearInterval(timer)
		}
		setIsExporting(false)
    }

    const selectAllExportConfigurations = () => {
		setSelectedExportConfigurations(exportConfigurations.map(exportConfiguration => exportConfiguration.id))
    }

    const selectNoExportConfigurations = () => {
		setSelectedExportConfigurations([])
    }

    const toggleExportConfiguration = (exportConfigurationId) => {
        var exportConfigurationIds = [...selectedExportConfigurations]
        var indexOfExportConfigurationId = exportConfigurationIds.indexOf(exportConfigurationId)
        if (indexOfExportConfigurationId == -1) {
            exportConfigurationIds.push(exportConfigurationId)
        } else {
			exportConfigurationIds.splice(indexOfExportConfigurationId, 1);
        }
        setSelectedExportConfigurations(exportConfigurationIds)
    }

    const exportPhotos = () => {
        console.log('exportPhotos')

        setShowNoExportConfigurationsSelectedError(false)
        setShowNoPhotosSelectedError(false)

        if (selectedExportConfigurations.length == 0) {
            setShowNoExportConfigurationsSelectedError(true)
            return
        }

        if (selectedPhotos.length == 0) {
            setShowNoPhotosSelectedError(true)
            return
        }

        var documentation = documentations[0]

        var exportRequest = {
            customerId: documentation.customerId,
            documentationId: documentation.id,
            exportConfigurationIds: selectedExportConfigurations,
            photoIds: selectedPhotos,
            params: exportParams
        }
        setIsExporting(true)
        setExportStartTime(moment())
        var timer = setInterval(() => {
            exportEventService.findAllByDocumentationName(documentationName).then(data => {
	            console.log(data)
	            data.sort((a, b) => a.dateCreated.localeCompare(b.dateCreated))
	            setExportEvents(data)
	        })
        }, 5000)
        setTimer(timer)
        documentationComparisonService.manualExport(exportRequest).then(data => {
            console.log(data)
            //onHideExportModal()
            //dispatch(alertActions.success(t('documentationcomparison.exportstarted')));
        })
    }

    const getExportStatus = (exportConfigurationId) => {
        var tmp = exportEvents.filter(exportEvent => {
            return exportEvent.exportConfigurationId == exportConfigurationId && moment(exportEvent.dateCreated).isSameOrAfter(exportStartTime, 'second')
        })
        if (tmp.length > 0) {
            if (tmp.some(exportEvent => !exportEvent.success)) {
                return <FontAwesomeIcon icon={faExclamationTriangle} style={{color: '#FF0000'}}/>
            } else {
                return <FontAwesomeIcon icon={faCheck} style={{color: '#1f6e43'}}/>
            }
        }
        return <img src="/spinner.gif"/>
    }

    function handleExportParamsChange(e, key) {
        let value = e.target.value

        let newParams = {...exportParams}
        newParams[key] = value

        setExportParams(newParams)
    }

    function zoom(photo) {
        setSelectedPhoto(photo)
        setShowPhotoModal(true)
    }

	return (
		<div>
			<div className="clearfix">
				<div className="float-left">
					<h1>
						{documentationName} {alias ? '(' + alias + ')' : ''}
					</h1>
	            </div>
				<div className="float-right">
					{ exportConfigurations.length != 0 && authService.hasRoles(['ROLE_EXPORT_DOCUMENTATION']) &&
                        <>
	                        <Button variant="outline-dark" onClick={() => setShowExportModal(true)}>
								<FontAwesomeIcon icon={faFileExport} style={{color: 'gray'}}/> {t('documentation.export')}
							</Button>
							&nbsp;
						</>
					}
					<Button variant="outline-dark" onClick={downloadPowerPoint}>
						<FontAwesomeIcon icon={faFilePowerpoint} style={{color: '#d04423'}}/> {t('documentationcomparison.downloadpowerpoint')}
					</Button>
					&nbsp;
					<Button variant="outline-dark" onClick={downloadZipFile}>
						<FontAwesomeIcon icon={faFileExport} style={{color: 'gray'}}/> {t('documentationcomparison.downloadzipfile')}
					</Button>
				</div>
			</div>
			<Row style={{marginBottom: '10px'}}>
				<Col md="12">
	                <b>{t('documentation.columns.exportedto')}:</b>
	                { exportEvents.length == 0 &&
	                    <>-</>
	                }
	                { exportEvents.length > 0 &&
	                    <>
			                <table>
			                    <tbody>
					                { exportEvents.map(exportEvent => {
										return <tr key={exportEvent.id}>
											<td>
												{moment(exportEvent.dateCreated).format(dateFormat)}
											</td>
											<td style={{paddingLeft: '15px'}}>
												{getTemplateForExportEvent(exportEvent)}
											</td>
											<td style={{paddingLeft: '15px'}}>
												{exportEvent.exportConfigurationName}
											</td>
											<td style={{paddingLeft: '15px'}}>
												{exportEvent.type}
											</td>
											{ exportEvents.some(ee => ee.username) &&
												<td style={{paddingLeft: '15px'}}>
													{exportEvent.username || '-'}
												</td>
											}
											<td style={{paddingLeft: '15px'}}>
												{ !exportEvent.success &&
													<FontAwesomeIcon icon={faExclamationTriangle} style={{color: '#FF0000'}}/>
												}
											</td>
										</tr>
					                })}
				                </tbody>
			                </table>
		                </>
	                }
                </Col>
            </Row>
			<Row style={{marginBottom: '10px'}}>
				<Col md="12">
					<b>{t('documentationcomparison.choosetemplates')}:</b>
					{ templates.map(template => {
						return <div key={"checkbox-" + template.id}>
							<label>
								<input type="checkbox" checked={true} checked={selectedTemplates.indexOf(template) != -1} onChange={() => toggleTemplate(template)}/>
								&nbsp;
								{template.name}
							</label>
						</div>
					})}
				</Col>
			</Row>
			<Row>
				<Col md="12">
					<table className="table-bordered">
						<thead>
							<tr>
								{ selectedTemplates.map(template => {
									return <th key={'th-' + template.id}>
										{template.name}
									</th>
								})}
							</tr>
						</thead>
						<tbody>
							{ templateTaskNames.map(templateTaskName => {
								return <tr key={templateTaskName}>
									{ selectedTemplates.map(template => {
										let templateTask = findTemplateTaskByName(template, templateTaskName)
										return <td key={'td-' + template.id + '-' + templateTaskName} style={{verticalAlign: 'top', padding: '5px'}}>
											{ templateTask &&
												<div>
													{templateTask.name}
													<br/>
													{ getPhotosForTemplateTask(template, templateTask.name).map(photo => {
														var photoName = photo.alias || photo.name
														return <>
															<div key={photo.id} style={{position: 'relative'}}>
																<a href="javascript:void(0)" onClick={() => togglePhoto(photo)}>
																	<img src={SERVER_URL + '/photo/thumbnail/' + photo.id} title={photoName} alt={photoName} style={{width: '200px'}}/>
																</a>
																<div style={{position: 'absolute', top: '5px', left: '5px'}}>
																	<input type="checkbox" checked={selectedPhotos.indexOf(photo.id) != -1} onChange={() => togglePhoto(photo)}/>
																</div>
																<div style={{position: 'absolute', top: '5px', right: '5px'}}>
																	<Button variant="light" size="sm" onClick={() => zoom(photo)}>
																		<FontAwesomeIcon icon={faMagnifyingGlass} style={{color: '#000000'}}/>
																	</Button>
																</div>
															</div>
															{ photo.comment &&
																<div>({photo.comment})</div>
															}
														</>
													})}
												</div>
											}
										</td>
									})}
								</tr>
							})}
						</tbody>
					</table>
				</Col>
			</Row>
			<div style={{height: '50px'}}>
			</div>
			<Modal
				style={{overflow: 'scroll'}}
				size="lg"
				show={showExportModal}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHideExportModal}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('documentation.export')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ !isExporting &&
						<>
							<Row>
								<Col sm="12">
									<Form.Label>
					                    {t('exportconfiguration.label')} (
					                    <a href="javascript:void(0)" onClick={selectAllExportConfigurations}>{t('documentation.selectall')}</a>
					                    &nbsp;/&nbsp;
					                    <a href="javascript:void(0)" onClick={selectNoExportConfigurations}>{t('documentation.selectnone')}</a>
					                    )
					                </Form.Label>
				                </Col>
			                </Row>
							<Row style={{marginBottom: '1rem'}}>
								<Col sm="12">
									{ exportConfigurations.sort((a, b) => a.name.localeCompare(b.name)).map(exportConfiguration => {
										return <Form.Group controlId={exportConfiguration.id} key={exportConfiguration.id} style={{marginBottom: '0px'}}>
											<Form.Label>
			                                    <Form.Check type="checkbox" name="export-configuration" label={exportConfiguration.name} checked={selectedExportConfigurations.indexOf(exportConfiguration.id) != -1} onChange={(e) => toggleExportConfiguration(exportConfiguration.id)} />
			                                </Form.Label>
			                            </Form.Group>
									})}
								</Col>
								{ showNoExportConfigurationsSelectedError &&
									<Col sm="12">
										<Alert variant="danger" style={{marginTop: '5px'}}>
						                    {t('documentation.noexportconfigurationsselectederror')}
						                </Alert>
					                </Col>
								}
								{ showNoPhotosSelectedError &&
									<Col sm="12">
										<Alert variant="danger" style={{marginTop: '5px'}}>
						                    {t('documentation.nophotosselectederror')}
						                </Alert>
					                </Col>
								}
							</Row>
							{ exportConfigurations.some(exportConfiguration => exportConfiguration.type == "RAMSITE_3GIS") &&
								<Row>
									<Col sm="12">
										<table style={{width: '100%'}}>
						                    { Object.entries(exportParams).length > 0 &&
						                        <thead>
							                        <tr>
							                            <th>
							                                {t('suggestionimportconfiguration.params.key')}
							                            </th>
							                            <th>
							                                {t('suggestionimportconfiguration.params.value')}
							                            </th>
							                        </tr>
						                        </thead>
						                    }
						                    <tbody>
								                {Object.entries(exportParams).map(([key, value], index) => {
													return <tr key={index}>
														<td>
															{key}
														</td>
														<td>
															<Form.Control type="text" placeholder={key} name="value" value={value} onChange={(e) => handleExportParamsChange(e, key)}/>
														</td>
													</tr>
								                })}
							                </tbody>
						                </table>
					                </Col>
				                </Row>
			                }
						</>
					}
					{ isExporting &&
						<table className="table">
							<tbody>
								{ selectedExportConfigurations.map(exportConfigurationId => {
									var exportConfiguration = exportConfigurations.find(exportConfiguration => exportConfiguration.id == exportConfigurationId)
									return <tr>
										<td style={{"border": "0px"}}>
											{exportConfiguration.name}
										</td>
										<td style={{"border": "0px"}}>
											{getExportStatus(exportConfigurationId)}
										</td>
									</tr>
								})}
							</tbody>
						</table>
					}
				</Modal.Body>
				<Modal.Footer>
					{ !isExporting &&
						<Button variant="outline-dark" onClick={exportPhotos}>
							{t('documentation.export')}
						</Button>
					}
					{ isExporting &&
						<Button variant="outline-dark" onClick={onHideExportModal}>
							{t('crudtable.ok')}
						</Button>
					}
				</Modal.Footer>
			</Modal>
			<Modal
				style={{overflow: 'scroll'}}
				size="xl"
				show={showPhotoModal}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHidePhotoModal}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedPhoto ? selectedPhoto.name : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ selectedPhoto &&
						<img src={SERVER_URL + '/photo/download/' + selectedPhoto.id} title={selectedPhoto.name} alt={selectedPhoto.name} style={{width: '100%'}}/>
					}
				</Modal.Body>
			</Modal>
        </div>
    )
}

export default DocumentationComparisonDetails;