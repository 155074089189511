import api from './api.service'

export const documentationService = {
    list,
    simpleList,
    findAllByName,
    findAllByCustomerId,
    get,
    save,
    update,
    approve,
    manualExport,
    _delete,
    toggleHidden,
    mergeDocumentations,
    getCreators,
    count,
    changeStatus,
    rename,
    requestExcelExport
};

const BASE_URL = '/documentations'

function list(params) {
    return api.get(BASE_URL, {...params})
}

function simpleList() {
    return api.get('/documentation/simpleList/')
}

function findAllByName(name, max) {
    return api.get('/documentation/findAllByName/' + name, {max: max || 500, sort: 'name', order: 'desc'})
}

function findAllByCustomerId(customerId) {
    return api.get('/documentation/findAllByCustomerId/' + customerId)
}

function get(id) {
    return api.get(BASE_URL + '/' + id)
}

function save(documentation) {
    return api.post(BASE_URL, documentation)
}

function update(id, documentation) {
    return api.put(BASE_URL + "/" + id, documentation)
}

function approve(id) {
    return api.get('/documentation/approve/' + id)
}

function manualExport(exportRequest) {
    return api.put('/documentation/manualExport/', exportRequest)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}

function toggleHidden(id) {
	return api.get('/documentation/toggleHidden/' + id)
}

function mergeDocumentations(request) {
	return api.put('/documentation/mergeDocumentations/', request)
}

function getCreators(request) {
	return api.get('/documentation/getCreators/')
}

function count() {
	return api.get('/documentation/count/')
}

function changeStatus(documentationId, status) {
	return api.get('/documentation/changeStatus/' + documentationId + '?status=' + status)
}

function rename(documentationId, name) {
	return api.get('/documentation/rename/' + documentationId + '?name=' + name)
}

function requestExcelExport() {
	return api.get('/documentation/requestExcelExport/')
}