import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Tabs, Tab, Card, Spinner, Dropdown } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { exportDocumentConfigurationService } from '../../_services';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PowerPoint from './powerpoint';
import Word from './word';
import Excel from './excel';
import PDF from './pdf';
import { history } from '../../_helpers';
import { alertActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ScrollUpButton from '../scrollupbutton.component';

function ExportDocumentConfigurationForm() {

	const { uuid } = useParams();
	const [state, setState] = useState({
		name: '',
		description: '',
		type: 'POWERPOINT',
		nodes: []
	});
	const [validated, setValidated] = useState(false);
	const [saving, setSaving] = useState(false);
	const [exportDocumentConfigurations, setExportDocumentConfigurations] = useState([]);
	const dispatch = useDispatch();
    const {t} = useTranslation('common');

    useEffect(() => {
        console.log('useEffect')
        if (uuid === "create") {
            exportDocumentConfigurationService.list().then(data => {
	            data.sort((a, b) => a.name.localeCompare(b.name))
	            setExportDocumentConfigurations(data)
	        })
        } else {
            exportDocumentConfigurationService.get(uuid).then(data => {
	            setState(data)
	        })
        }
    }, [])

    const handleSubmit = (event) => {
        console.log('handleSubmit')
	    event.preventDefault();

	    const form = event.currentTarget;
	    if (form.checkValidity() === false) {
	        event.stopPropagation();
	        setValidated(true);
	    } else {
	        handleConfirm(state)
	        setValidated(false);
	    }
    }

    function handleConfirm(state) {
        setSaving(true)
        if (uuid === "create") {
            exportDocumentConfigurationService.save(state).then(data =>  {
                setSaving(false)
                history.push("/exportDocumentConfiguration")
                dispatch(alertActions.success(t('alerts.createsuccessful')))
			})
        } else {
            exportDocumentConfigurationService.update(state.id, state).then(data =>  {
                setSaving(false)
                history.push("/exportDocumentConfiguration")
				dispatch(alertActions.success(t('alerts.updatesuccessful')))
			})
        }
    }

    const handleChange = (e) => {
	    const target = e.target
	    const name = target.name
	    const value = target.type === 'checkbox' ? target.checked : target.value;
	    setState( prevState => ({
	        ...prevState,
	        [name]: value
		}));
	}

    function handleTypeChange(e) {
        e.preventDefault()

        handleChange({target: {type: "select", name: "type", value: e.target.value}})
        handleChange({target: {type: "object", name: "nodes", value: []}})
    }

    function handleExportDocumentConfigurationChange(e) {
        var value = e.target.value
        if (value != -1) {
            var exportDocumentConfiguration = exportDocumentConfigurations.find(exportDocumentConfiguration => exportDocumentConfiguration.id == e.target.value)
	        handleChange({target: {type: "text", name: "name", value: exportDocumentConfiguration.name + ' - Kopia'}})
	        handleChange({target: {type: "text", name: "description", value: exportDocumentConfiguration.description}})
	        handleChange({target: {type: "text", name: "type", value: exportDocumentConfiguration.type}})
	        var nodes = []
	        exportDocumentConfiguration.nodes.forEach(node => {
	            console.log(node)
	            visitNode(null, node)
	            nodes.push(node)
	        })
	        handleChange({target: {type: "object", name: "nodes", value: nodes}})
        }
    }

    const visitNode = (parent, node) => {
        node.id = uuidv4()
        if (parent) {
            node.parent = parent.id
        } else {
            node.exportDocumentationConfiguration = null
        }
        node.children.forEach(child => {
            visitNode(node, child)
        })
    }

    const onDragEnd = (result, parent) => {
        console.log('onDragEnd')
        console.log(parent)

        const { destination, source } = result

        if (!destination) {
            return
        }

        if (destination.index === source.index) {
            return
        }

        if (parent) {
			var children = parent.children

			var node = children[source.index]
	        children.splice(source.index, 1)
	        children.splice(destination.index, 0, node)
	        children.map((node, index) => {
	            node.position = index
	        })
        } else {
			var nodes = state.nodes.filter(child => child.parent == null)

	        var node = nodes[source.index]
	        nodes.splice(source.index, 1)
	        nodes.splice(destination.index, 0, node)
	        nodes.map((node, index) => {
	            node.position = index
	        })
        }

        state.nodes.sort((a, b) => a.position - b.position)
        handleChange({target: {type: "object", name: "nodes", value: state.nodes}})
    }

    function addNode(e, parent, type) {
        e.preventDefault();

        var node = {
            id: uuidv4(),
            name: '',
            description: '',
            type: type,
            position: 0,
            params: {},
            children: []
        }

        if (type == 'EACH') {
            node.params['IN'] = 'PHOTOS'
        }

        if (type == 'TEXT' && state.type == 'POWERPOINT') {
            node.params['SIZE'] = 32
            node.params['COLOR'] = '#000000'
        } else if (type == 'TEXT' && (state.type == 'WORD' || state.type == 'WORD_TO_PDF')) {
            node.params['SIZE'] = 11
            node.params['COLOR'] = '#000000'
            node.params['STYLE'] = 'NORMAL'
            node.params['ALIGNMENT'] = 'LEFT'
        } else if (type == 'TEXT' && state.type == 'EXCEL') {
            node.params['SIZE'] = 11
            node.params['COLOR'] = '#000000'
        } else if (type == 'TEXT' && state.type == 'PDF') {
            node.params['SIZE'] = 11
            node.params['COLOR'] = '#000000'
            node.params['FONT'] = 'HELVETICA'
        }

		let newNodes = [...state.nodes]

        if (parent) {
            node.parent = parent.id
            parent.children.push(node)

            parent.children.forEach((sibling, index) => {
	            sibling.position = index
	        })
        } else {
            var maxIndex = -1
            state.nodes.forEach((root, index) => {
                root.position = index
	            maxIndex = index
	        })
	        node.position = maxIndex+1

	        newNodes.push(node)
        }

        handleChange({target: {type: "object", name: "nodes", value: newNodes}})
    }

    function removeNode(e, node, parent) {
        console.log('removeNode')

        e.preventDefault()

		let newNodes = [...state.nodes]

        if (parent) {
            console.log(parent.children)
            parent.children = parent.children.filter(child => child != node)
            console.log(parent.children)

            parent.children.forEach((child, index) => {
                child.position = index
	        })
        } else {
            newNodes = newNodes.filter(root => root != node)
            newNodes.forEach((root, index) => {
                root.position = index
	        })
        }

        handleChange({target: {type: "object", name: "nodes", value: newNodes}})
    }

    function copyNode(e, node, parent) {
        console.log('copyNode')

        e.preventDefault()

		var node = {
            id: uuidv4(),
            name: node.name,
            description: node.description,
            type: node.type,
            position: 0,
            params: node.params,
            children: []
        }

		let newNodes = [...state.nodes]

        if (parent) {
            node.parent = parent.id
            parent.children.push(node)

            parent.children.forEach((sibling, index) => {
	            sibling.position = index
	        })
        } else {
            var maxIndex = -1
            state.nodes.forEach((root, index) => {
                root.position = index
	            maxIndex = index
	        })
	        node.position = maxIndex+1

	        newNodes.push(node)
        }

        handleChange({target: {type: "object", name: "nodes", value: newNodes}})
    }

    function handleNodeChanges(e, node) {
        var targetName = e.target.name
        var value = e.target.value

        if (targetName == 'DATA') {
            let file = e.target.files[0]

	        if (file) {
	            console.log(file)
	            const reader = new FileReader()
	            reader.addEventListener('load', (event) => {
	                let binaryString = event.target.result;
	                let hexString = btoa(binaryString)
	                node.params["DATA"] = hexString
	                node.params["TYPE"] = file.type
	                handleChange({target: {type: "object", name: "nodes", value: state.nodes}})
	            });
	            reader.readAsBinaryString(file)
	        }
        } else {
            node.params[targetName] = value
            handleChange({target: {type: "object", name: "nodes", value: state.nodes}})
        }
    }

    const toggleNode = node => {
        node.collapsed = !node.collapsed
        handleChange({target: {type: "object", name: "nodes", value: state.nodes}})
    }

    const closeAll = () => {
        const nodes = flatten()
        nodes.forEach(node => {
            node.collapsed = true
        })
        handleChange({target: {type: "object", name: "nodes", value: state.nodes}})
    }

    const openAll = () => {
        const nodes = flatten()
        nodes.forEach(node => {
            node.collapsed = false
        })
        handleChange({target: {type: "object", name: "nodes", value: state.nodes}})
    }

    const flatten = () => {
        var result = []
        var queue = [...state.nodes]
        while (queue.length > 0) {
            var node = queue.shift()
            result.push(node)
            node.children.forEach(child => {
                queue.push(child)
            })
        }
        return result
    }

    const hasParentWithNodeType = (node, nodeType) => {
		const nodes = flatten()
		var parentId = node.parent
		var parentNodeTypes = []
		while (parentId) {
			var parent = nodes.find(parent => parent.id == parentId)
			parentNodeTypes.push(parent.type)
			parentId = parent.parent
		}
		return parentNodeTypes.includes(nodeType)
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
            <Row>
                <Col md="4">
		            { !state.dateCreated &&
		                <Form.Group controlId="exportConfiguration">
		                    <Form.Label>
			                    {t('exportdocumentconfiguration.copyfrom')}
			                </Form.Label>
							<Form.Control required as="select" name="template" value={-1} onChange={handleExportDocumentConfigurationChange}>
								<option value={-1}>{t('exportdocumentconfiguration.chooseexportdocumentconfiguration')}</option>
			                    {exportDocumentConfigurations.map(exportDocumentConfiguration => (
			                        <option key={exportDocumentConfiguration.id} value={exportDocumentConfiguration.id}>
			                            {exportDocumentConfiguration.name}
			                        </option>
			                    ))}
			                </Form.Control>
		                </Form.Group>
		            }
		            <Form.Group controlId="name">
		                <Form.Label>
		                    {t('exportdocumentconfiguration.columns.name')} *
		                </Form.Label>
		                <Form.Control required type="text" placeholder={t('template.columns.name')} name="name" value={state.name} onChange={handleChange} />
		            </Form.Group>
		            <Form.Group controlId="description">
		                <Form.Label>
		                    {t('exportdocumentconfiguration.columns.description')}
		                </Form.Label>
		                <Form.Control type="text" placeholder={t('exportdocumentconfiguration.columns.description')} name="description" value={state.description} onChange={handleChange} />
		            </Form.Group>
		            <Form.Group controlId="type">
		                <Form.Label>
		                    {t('exportdocumentconfiguration.columns.type')}
		                </Form.Label>
		                <Form.Control required as="select" placeholder={t('exportdocumentconfiguration.columns.type')} name="type" value={state.type} onChange={handleTypeChange} disabled={state.dateCreated}>
		                    <option>POWERPOINT</option>
		                    <option>WORD</option>
		                    <option>EXCEL</option>
		                    <option>PDF</option>
		                    <option value="WORD_TO_PDF">WORD TO PDF</option>
		                </Form.Control>
		            </Form.Group>
		            <Button variant="secondary" onClick={() => history.push("/exportDocumentConfiguration")}>
			            {t('crudtable.cancel')}
			        </Button>
			        <Button form='data-form' type="submit" variant="primary">
			            { saving &&
			                <>
			                    {t('crudtable.saving')}
					            &nbsp;
					            <Spinner animation="border" role="status" size="sm">
									<span className="sr-only">Loading...</span>
								</Spinner>
			                </>
			            }
			            { !saving &&
			                <>
			                    {t('crudtable.save')}
			                </>
			            }
			        </Button>
	            </Col>
	            <Col md="8">
		            <Form.Group controlId="nodes">
		                <div className="clearfix" style={{marginBottom: '5px'}}>
							<div className="float-left">
				                <Form.Label>
				                    {t('exportdocumentconfiguration.columns.nodes')}
				                </Form.Label>
			                </div>
			                <div className="float-right">
			                    <Button variant="outline-dark" onClick={closeAll}>
									{t('exportdocumentconfiguration.closeall')}
								</Button>
								<Button variant="outline-dark" onClick={openAll}>
									{t('exportdocumentconfiguration.openall')}
								</Button>
			                </div>
		                </div>
		                { state.type == 'POWERPOINT' &&
		                    <PowerPoint
		                        nodes={state.nodes}
		                        addNode={addNode}
								removeNode={removeNode}
								copyNode={copyNode}
								onDragEnd={onDragEnd}
								toggleNode={toggleNode}
							/>
		                }
		                { (state.type == 'WORD' || state.type == 'WORD_TO_PDF') &&
		                    <Word
		                        nodes={state.nodes}
		                        addNode={addNode}
								removeNode={removeNode}
								copyNode={copyNode}
								onDragEnd={onDragEnd}
								toggleNode={toggleNode}
							/>
		                }
		                { state.type == 'EXCEL' &&
		                    <Excel
		                        nodes={state.nodes}
		                        addNode={addNode}
								removeNode={removeNode}
								copyNode={copyNode}
								onDragEnd={onDragEnd}
								toggleNode={toggleNode}
								hasParentWithNodeType={hasParentWithNodeType}
							/>
		                }
		                { state.type == 'PDF' &&
		                    <PDF
		                        nodes={state.nodes}
		                        addNode={addNode}
								removeNode={removeNode}
								copyNode={copyNode}
								onDragEnd={onDragEnd}
								toggleNode={toggleNode}
								hasParentWithNodeType={hasParentWithNodeType}
							/>
		                }
		            </Form.Group>
	            </Col>
            </Row>
            <ScrollUpButton/>
        </Form>
    )
}

export default ExportDocumentConfigurationForm