import React, { useState, useEffect } from 'react'
import { licenseService } from '../../_services'
import { Button, Modal, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Select from 'react-select'
import { SERVER_URL } from '../../config';

function InvoiceAttachement(props) {

	const [show, setShow] = useState(false)
    const [backdrop, setBackdrop] = useState(false)
    const [startDate, setStartDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'))
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
	const [licenses, setLicenses] = useState([])
	const [selectedLicenses, setSelectedLicenses] = useState([])
    const {t} = useTranslation('common')

	const onClick = () => {
		licenseService.simpleList().then(licenses => {
			setLicenses(licenses.filter(license => license.customerId == props.customerId))
			setShow(true)
			setBackdrop(true)
		})
    }

    const onHide = () => {
		setShow(false)
		setBackdrop(false)
    }

    const onLicenseChange = e => {
        if (e) {
            setSelectedLicenses(e)
        } else {
            setSelectedLicenses([])
        }
    }

    const confirm = () => {
        onHide()

		const token = 'RZrtT2Uyva2tQ34U6OE9hQkluE44VoHk1yu0vfYDv0zz0zW6m42yAFWKgdtCg0Fy'
		var url = SERVER_URL + '/customer/createInvoiceAttachement/'
		url += '?customerId=' + props.customerId
		url += '&startDate=' + startDate
		url += '&endDate=' + endDate
		if (selectedLicenses.length > 0) {
			url += '&licenses=' + selectedLicenses.map(license => license.id).join(';')
		}
		url += '&token=' + token
		console.log(url)

		//setSelectedLicenses([])

        window.location.href = url
    }

	return (
		<>
			<Button title="Skapa fakturabilaga" variant="outline-secondary" size="sm" onClick={onClick}>
				<FontAwesomeIcon icon={faFile}/>
			</Button>
			<Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						Skapa fakturabilaga
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group controlId="startDate">
						<Form.Label>
			                Startdatum
			            </Form.Label>
						<Form.Control
			                type="date"
			                name="startDate"
			                value={startDate}
			                onChange={e => setStartDate(e.target.value)}
			                required={true}
			                max={endDate}
			            />
		            </Form.Group>
		            <Form.Group controlId="endDate">
						<Form.Label>
			                Slutdatum
			            </Form.Label>
						<Form.Control
			                type="date"
			                name="endDate"
			                value={endDate}
			                onChange={e => setEndDate(e.target.value)}
			                required={true}
			                min={startDate}
			                max={moment().format('YYYY-MM-DD')}
			            />
		            </Form.Group>
		            <Form.Group controlId="licenses">
						<Form.Label>
			                Licenser
			            </Form.Label>
			            <Select
			                placeholder="Licenser"
			                isLoading={licenses.length === 0}
			                options={licenses}
			                value={selectedLicenses}
			                getOptionLabel={license => license.name ? license.name + ' (' + license.licenseCode + ')' : license.licenseCode}
			                getOptionValue={license => license.id}
			                isMulti={true}
			                closeMenuOnSelect={false}
			                onChange={onLicenseChange}
			            />
		            </Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={onHide}>
						{t('crudtable.cancel')}
					</Button>
					<Button variant="danger" onClick={confirm}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default InvoiceAttachement