import React from 'react';
import CrudTable from '../table/crudtable'
import { exportConfigurationService } from '../../_services'
import ExportConfigurationForm from './exportconfigurationform.component'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import { SERVER_URL } from '../../config';

function ExportConfiguration() {

	const {t} = useTranslation('common');

	const initialState = {
		customerId: -1,
		name: '',
		type: 'SHAREPOINT',
		params: { clientId: '', tenantId: '', siteUrl: '', scope: '', userColumn: '', commentColumn: '', sharedDocuments: '' },
		userParams: []
	}

	const formatParams = function(cell) {
		return <>
			{ Object.keys(cell).map((key, index) => {
				return <div key={key}>{key}: {cell[key]}</div>
			})}
		</>
	}

	const paramsFilterValues = function(cell) {
		return Object.keys(cell).map((key, index) => {
			return key + ": " + cell[key]
		}).join(" ")
	}

	const formatTest = function(cell, row) {
		return <a href={SERVER_URL + '/exportConfiguration/test/' + row.id} target="_blank">Test</a>
	}

	const columns = [
		{
			dataField: 'name',
			text: t('exportconfiguration.columns.name'),
			sort: true
		},
		{
			dataField: 'customerName',
			text: t('exportconfiguration.columns.customer'),
			sort: true
		},
		{
			dataField: 'type',
			text: t('exportconfiguration.columns.type'),
			sort: true
		},
		{
			dataField: 'params',
			text: t('exportconfiguration.columns.params'),
			formatter: formatParams,
			filterValue: paramsFilterValues
		},
		{
			dataField: 'test',
			text: t('exportconfiguration.columns.test'),
			formatter: formatTest,
			headerAttrs: { width: 60 }
		}
	]

	return (
		<div>
			<h2>
				{t('exportconfiguration.label')}
			</h2>
			<CrudTable
				service={exportConfigurationService}
				columns={columns}
				initialState={initialState}
				form={<ExportConfigurationForm />}
				sort={{dataField: 'customerName', order: 'asc'}}
				modalSize="lg"
			/>
		</div>
	)
}

export default ExportConfiguration;