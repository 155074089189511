import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { customerService, suggestionListService, suggestionImportConfigurationService } from '../../_services'

const SuggestionImportConfigurationForm = ({data, handleChange, handleSubmit, validated}) => {

	const [suggestionLists, setSuggestionLists] = useState([])
	const [suggestionImportConfigurations, setSuggestionImportConfigurations] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        suggestionListService.list(1000).then(suggestionsLists => {
            suggestionsLists.sort((a, b) => {
                if (a.name != b.name) {
                    return a.name.localeCompare(b.name)
                }
                return a.customerName.localeCompare(b.customerName)
            })
            setSuggestionLists(suggestionsLists)
            if (data.suggestionList === '' && suggestionsLists.length > 0) {
                handleChange({target: {type: "select", name: "suggestionList", value: suggestionsLists[0].id}})
            }
        })
        suggestionImportConfigurationService.list(1000).then(suggestionImportConfigurations => {
            suggestionImportConfigurations.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            setSuggestionImportConfigurations(suggestionImportConfigurations)
        })
    }, [])

    function handleTypeChange(e) {
        e.preventDefault()

		var newParams = {}
        if (e.target.value === 'RAMSITE_3GIS') {
            newParams.apiKey = ''
			newParams.siteStatus = ''
			newParams.projectId = ''
			newParams.projectType = ''
			newParams.projectStatus = ''
			newParams.projectClassification = ''
			newParams.area = ''
			newParams.smallArea = ''
			newParams.rolloutPhase = ''
			newParams.siteType = ''
			newParams.propertyType = ''
        } else if (e.target.value === 'SHAREPOINT_FOLDERS') {
            newParams.clientId = ''
            newParams.tenantId = ''
			newParams.siteUrl = ''
			newParams.scope = ''
			newParams.path = ''
        }

        handleChange({target: {type: "object", name: "params", value: newParams}})
        handleChange({target: {type: "select", name: "type", value: e.target.value}})
    }

    function handleValueChange(e, key) {
        let value = e.target.value

        let newParams = {...data.params}
        newParams[key] = value

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function handleSuggestionImportConfigurationChange(e) {
        var suggestionImportConfiguration = suggestionImportConfigurations.find(suggestionImportConfiguration => suggestionImportConfiguration.id == e.target.value)
        handleChange({target: {type: "text", name: "name", value: suggestionImportConfiguration.name}})
        handleChange({target: {type: "text", name: "type", value: suggestionImportConfiguration.type}})
        handleChange({target: {type: "text", name: "frequency", value: suggestionImportConfiguration.frequency}})
        handleChange({target: {type: "object", name: "params", value: {...suggestionImportConfiguration.params}}})
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
            { !data.dateCreated &&
                <Form.Group controlId="exportConfiguration">
                    <Form.Label>
	                    {t('suggestionimportconfiguration.copyfrom')}
	                </Form.Label>
					<Form.Control required as="select" name="template" value={-1} onChange={handleSuggestionImportConfigurationChange}>
						<option value={-1}>{t('suggestionimportconfiguration.choosesuggestionimportconfiguration')}</option>
	                    {suggestionImportConfigurations.map(suggestionImportConfiguration => (
	                        <option key={suggestionImportConfiguration.id} value={suggestionImportConfiguration.id}>
	                            {suggestionImportConfiguration.name}
	                        </option>
	                    ))}
	                </Form.Control>
                </Form.Group>
            }
            <Form.Group controlId="name">
                <Form.Label>
                    {t('suggestionimportconfiguration.columns.name')} *
                </Form.Label>
                <Form.Control required type="text" placeholder={t('template.columns.name')} name="name" value={data.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="suggestionList">
                <Form.Label>
                    {t('suggestionimportconfiguration.columns.suggestionlist')}
                </Form.Label>
                <Form.Control required as="select" name="suggestionList" value={data.suggestionList} onChange={handleChange}>
                    {suggestionLists.map(suggestionList => (
                        <option key={suggestionList.id} value={suggestionList.id}>{suggestionList.name} ({suggestionList.customerName})</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="type">
                <Form.Label>
                    {t('suggestionimportconfiguration.columns.type')}
                </Form.Label>
                <Form.Control required as="select" name="type" value={data.type} onChange={handleTypeChange}>
                    <option>RAMSITE_3GIS</option>
                    <option>SHAREPOINT_FOLDERS</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="frequency">
                <Form.Label>
                    {t('suggestionimportconfiguration.columns.frequency')}
                </Form.Label>
                <Form.Control required as="select" name="frequency" value={data.frequency} onChange={handleChange}>
                    <option>DAILY</option>
                    <option>WEEKLY</option>
                    <option>MONTHLY</option>
                    <option>YEARLY</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="disabled">
                <Form.Label>
                    <Form.Check type="checkbox" name="disabled" label={t('suggestionimportconfiguration.columns.disabled')} checked={data.disabled} onChange={handleChange} />
                </Form.Label>
            </Form.Group>
            <Form.Group controlId="params">
                <Form.Label>
                    {t('suggestionimportconfiguration.columns.params')}
                </Form.Label>
                <br/>
                <table style={{width: '100%'}}>
                    { Object.entries(data.params).length > 0 &&
                        <thead>
	                        <tr>
	                            <th>
	                                {t('suggestionimportconfiguration.params.key')}
	                            </th>
	                            <th>
	                                {t('suggestionimportconfiguration.params.value')}
	                            </th>
	                        </tr>
                        </thead>
                    }
                    <tbody>
		                {Object.entries(data.params).map(([key, value], index) => {
							return <tr key={index}>
								<td>
									{key}
								</td>
								<td>
									<Form.Control type="text" placeholder={key} name="value" value={value} onChange={(e) => handleValueChange(e, key)}/>
								</td>
							</tr>
		                })}
	                </tbody>
                </table>
                <div style={{paddingTop: '10px'}}>
                    {t('suggestionimportconfiguration.params.explanation')}
                </div>
            </Form.Group>
        </Form>
    )
}

export default SuggestionImportConfigurationForm