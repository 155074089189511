import React from 'react';
import CrudTable from '../table/crudtable'
import { templateService, authService } from '../../_services'
import { useTranslation } from "react-i18next";
import { history } from '../../_helpers';

function TemplateList() {

    const {t} = useTranslation('common');

    const initialState = {
		customerId: 1,
		name: '',
		description: '',
		createPowerPoint: false,
		createWordDocument: false,
		createExcelWorkbook: false,
		exportUnzippedPhotos: true,
		createZipFile: false,
		exportAutomatically: false,
		locationEnabled: false,
		locationRequired: false,
		graphicEnabled: false,
		deleteAutomatically: false,
		deleteTimeLimit: null,
		position: 0,
		suggestionList: null,
		onlySuggestionsAllowed: false,
		allTasksRequired: 'NO',
		hidden: false,
		groups: [],
		tasks: [],
		exportConfigurations: [],
		templateGraphics: []
    }

    const formatDescription = function(cell) {
        return cell ? <a>{cell}</a> : '-'
    }

    const formatExportConfigurations = function(cell) {
        return cell && cell.length > 0 ? cell.map(exportConfiguration => exportConfiguration.name).join(', ') : '-'
    }

    const formatBoolean = function(cell) {
        return cell ? t('crudtable.yes') : t('crudtable.no')
    }

    const getSort = () => {
	    if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
	        return {dataField: 'customerName', order: 'asc'}
	    }
	    return {dataField: 'position', order: 'asc'}
    }

    const onAddClick = (row) => {
	    console.log('onAddClick')
		history.push("/template/create")
    }

    const onEditClick = (row) => {
	    console.log('onEditClick')
		history.push("/template/" + row.id)
    }

    const formatName = function(cell, row) {
		return <a href={'/template/details/' + row.id}>{cell}</a>
	}

  const columns = [
    {
      dataField: 'customerName',
      text: t('template.columns.customer'),
      sort: true,
      hidden: !authService.hasRoles(['ROLE_SUPER_ADMIN'])
    },
    {
      dataField: 'name',
      text: t('template.columns.name'),
      formatter: formatName,
      sort: true
    },
    {
      dataField: 'description',
      text: t('template.columns.description'),
      formatter: formatDescription,
      style: { maxWidth: '500px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
    },
    {
      dataField: 'exportConfigurations',
      text: t('template.columns.exportconfigurations'),
      formatter: formatExportConfigurations,
      filterValue: formatExportConfigurations
    },
    {
      dataField: 'suggestionListName',
      text: t('template.columns.suggestionlist'),
      sort: true
    },
    {
      dataField: 'hidden',
      text: t('template.columns.hidden'),
      formatter: formatBoolean,
      filterValue: formatBoolean,
      sort: true
    },
    {
      dataField: 'position',
      text: t('template.columns.position'),
      sort: true
    }
  ]

	return (
		<div>
            <h2>
				{t('template.label')}
            </h2>
			<CrudTable
				service={templateService}
				columns={columns}
				initialState={initialState}
				//form={<TemplateForm />}
				remote={{"filter": false, "search": false, "pagination": false}}
				sort={getSort()}
				modalSize="xl"
				creatable={authService.hasRoles(['ROLE_TEMPLATE_WRITE'])}
				editable={authService.hasRoles(['ROLE_TEMPLATE_WRITE'])}
				deletable={authService.hasRoles(['ROLE_TEMPLATE_WRITE'])}
				onAddClick={onAddClick}
				onEditClick={onEditClick}
            />
		</div>
  )
}

export default TemplateList;